<template>
  <div class="Userhl_msg">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="系统消息" class="msgxitong" name="first">
        <ul class="Userhl_msgznmsgul">
          <li class="Userhl_msgznmsgulallbtn" v-if="msgyueduallbtn">
            <el-button
              icon="el-icon-document-copy"
              @click="readmsgall(msgid.length)"
              type="info"
              plain
              >全部已读</el-button
            >
            <el-button
              icon="el-icon-delete"
              type="info"
              @click="deleteznmsg(0, msgid.length)"
              plain
              >全部删除</el-button
            >
          </li>
          <li
            class="Userhl_msgznmsgulli"
            v-for="(item, index) in znmsg"
            :key="index"
          >
            <div class="Userhl_msgznmsgullil">{{ item.title }}</div>
            <div class="Userhl_msgznmsgullir">
              <el-button
                icon="el-icon-document-copy"
                @click="open(item.title, item.content, item.id)"
                plain
                >详情</el-button
              >
              <el-button
                v-if="item.read_status == '1' ? true : false"
                icon="el-icon-circle-check"
                disabled
                plain
                >已读</el-button
              >
              <el-button
                icon="el-icon-delete"
                @click="deleteznmsg(item.id, 1)"
                plain
                >删除</el-button
              >
            </div>
          </li>
          <li class="lizero" v-if="lizero" style="width:100%  !important;"> 
            <img
              src="https://task.hozoin.cn/Works/20210426/sj-3936605600.png"
              alt=""
            />
          </li>
        </ul>
      </el-tab-pane>
      <el-tab-pane label="收到的评论" name="two">
        <ul class="pinglunmsg">
          <li v-for="(item,index) in plall" :key="index">
            <p>{{item.title}}</p>
            <p v-html="item.content"></p>
          </li>
        </ul>
      </el-tab-pane>
      <el-tab-pane label="收到的关注" name="three">
        <ul class="Userhl_msgznmsgul" style="padding-top: 1rem">
          <li
            class="Userhl_msgznmsgulli"
            v-for="(item, index) in gzall"
            :key="index"
          >
            <div class="Userhl_msgznmsgullil Userhl_gz">
              <div><img :src="item.userpic" alt="" /></div>
              <div>
                <p>{{ item.username }}</p>
                <p>{{ item.saytext }}</p>
              </div>
            </div>
            <div class="Userhl_msgznmsgullir">
              <el-button
                v-show="item.gz == 1 ? true : false"
                icon="el-icon-circle-plus-outline"
                plain
                @click="addremovegz(item.userid, index, 1)"
                >加关注</el-button
              >
              <el-button
                v-show="item.gz == 0 ? true : false"
                @click="addremovegz(item.userid, index, 2)"
                icon="el-icon-user"
                type="success"
                plain
                >已关注</el-button
              >
            </div>
          </li>
          <li class="lizero" v-if="lizero" >
            <img
              src="https://task.hozoin.cn/Works/20210426/sj-3936605600.png"
              alt=""
            />
          </li>
          <li class="lizero" v-if="lizero1"></li>
          <li class="lizero" v-if="lizero2"></li>
        </ul>
      </el-tab-pane>
    </el-tabs>
    <el-pagination
      :pager-count="5"
      @current-change="currentchange_gz"
      :page-size="limit"
      background
      layout="prev, pager, next"
      :total="total_gz"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      activeName: "first",
      limit: 8,
      znmsg: [],
      msgid: [],
      total_gz: 0,
      gzall: [],
      plall:[],
      lizero1: false,
      lizero2: false,
      userinfo: {},
      msgyueduallbtn:false,
      lizero:false,
    };
  },
  methods: {
    handleClick(tab, event) {
      if (this.activeName == "first") {
        this.msgxt(1);
      }else if(this.activeName == "three"){
         this.gzalllist("1");
      }else{
        this.zhanneimsg("1");
      }
    },
    open(title, text, id) {
      var that = this;
      that.$alert(text, title, {
        confirmButtonText: "确定",
        callback: (action) => {
          that.readmsg(id);
        },
      });
    },
    readmsgall(num) {
      var that = this;
      for (var i = 0; i < num; i++) {
        this.readmsg(that.msgid[i]);
      }
    },
    readmsg(id) {
      var that = this;
      var userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/messageRead",
          method: "post",
          data: {
            userid: userinfo.userid,
            id,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            that.$message("消息已读");
            setTimeout(() => {
              location.reload();
            }, 500);
          }
        });
    },
    deleteznmsg(id, num) {
      var that = this;
      if (num == 1) {
        this.deletemsg(id);
      } else {
        for (var i = 0; i < num; i++) {
          this.deletemsg(that.msgid[i]);
        }
      }
    },
    deletemsg(id) {
      var that = this;
      var userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
      that
        .$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/messageDelete",
          method: "post",
          data: {
            userid: userinfo.userid,
            id,
          },
        })
        .then((res) => {
          if (res.data.code == 1) {
            that.$message("删除成功");
            that.msgxt(1);
          }
        });
    },
    currentchange_gz(index) {
      if (this.activeName == "first") {
        this.msgxt(index);
      }else if(this.activeName == "three"){
         this.gzalllist(index);
      }else{
        this.zhanneimsg(index);
      }
      
    },
    gzalllist(page) {
      var that = this;
      var userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/getFollowFans",
        method: "post",
        data: {
          userid: userinfo.userid,
          f_type: "2",
          page,
          limit: that.limit,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.lizero=false;
          if(res.data.result.count==0){
            that.lizero=true;
          }
          var countzero = res.data.result.count % 4;
          if (countzero == 2) {
            that.lizero2 = true;
            that.lizero1 = true;
          } else if (countzero == 3) {
            that.lizero1 = true;
          }
          that.total_gz = res.data.result.count;
          that
            .$axios({
              headers: { "X-CSRF-TOKEN": that.userinfo.token },
              url: "/api/getFollowFans",
              method: "post",
              data: {
                userid: userinfo.userid,
                f_type: "1", //我关注的人
                page: 1,
                limit: 10000,
              },
            })
            .then((re) => {
              if (re.data.code == 1) {
                for (var s = 0; s < res.data.result.list.length; s++) {
                  for (var i = 0; i < re.data.result.list.length; i++) {
                    if (
                      re.data.result.list[i].userid ==
                      res.data.result.list[s].userid
                    ) {
                      res.data.result.list[s].gz = 0;
                      break;
                    } else {
                      res.data.result.list[s].gz = 1;
                    }
                  }
                }
                that.gzall = res.data.result.list;
              }
            });
        }else{
          that.lizero=true;
        }
      });
    },
    addremovegz(id, indexid, type) {
      if (type == 1) {
        //关注
        type = "getFollow";
        console.log("加关注");
      } else {
        type = "getCancelFollow";
        console.log("取关");
      }
      var types = type;
      var that = this;
      var userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/" + type + "",
        method: "post",
        data: {
          member_id: id,
          userid: userinfo.userid,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          if (that.gzall[indexid].gz == 0) {
            that.gzall[indexid].gz = 1;
            that.$message("取消关注成功");
          } else {
            that.gzall[indexid].gz = 0;
            that.$message({
              message: "添加关注成功",
              type: "success",
            });
          }
        }
      });
    },
    msgxt(page) {
      var that = this;
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/messageList",
        method: "post",
        data: {
          userid: that.userinfo.userid,
          page,
          limit: that.limit,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.msgyueduallbtn=true;
          that.lizero=false;
          that.msgid = [];
          that.total_gz = res.data.result.count;
          that.znmsg = res.data.result.list;
          for (var i = 0; i < res.data.result.list.length; i++) {
            that.msgid.push(res.data.result.list[i].id);
          }
        }else{
          that.msgyueduallbtn=false;
          that.lizero=true;
        }
      });
    },
    zhanneimsg(page){
      var that = this;
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token},
        url: "/api/messageComment",
        method: "post",
        data: {
          page,
          limit: that.limit,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.lizero=false;
          that.plall=res.data.result.list;
        }else{
          that.lizero=true;
        }
      });
    }
  },
  created() {
    var that = this;
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
    this.msgxt(1);
  },
};
</script>
